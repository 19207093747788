<script>
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  BookIcon,
  MessageCircleIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import countTo from "vue-count-to";
/**
 * Page-contact-three component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Footer,
    UserIcon,
    ArrowUpIcon,
    MailIcon,
    BookIcon,
    MessageCircleIcon,
    countTo,
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section
      class="bg-half bg-light d-table w-100"
      style="background: url('images/contact-detail.jpg') center center"
    >
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title text-white">Member Support Service</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    



                                  <li class="breadcrumb-item"><a href="/support">Support</a></li>
                                  <li class="breadcrumb-item"><router-link to="/knowledgebase">Knowledgebase</router-link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Ticket</li>








                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

 

    <!-- Start Contact -->







    <section class="section mt-40 bg-light">
      <div class="container">
        <div class="row align-items-center mb-4 pb-2">
          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">
              <h6 class="my-color-1"> Welcome back! Honourable Member,</h6>
              <h4 class="title mb-4 mb-lg-0">
                What problem<br />
                are you trying to solve?
              </h4>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">
              <p class="text-muted mb-0 mx-auto para-desc">
                We <i class="mdi mdi-heart heart text-danger"></i> to answer tickets via our system and always pursue relationships based on transparency and integrity. 
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

    <News />
        <!--end row-->
      </div>
      <!--end container-->



















      <div class="container  mt-100">
        <div class="row justify-content-center" id="counter">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="mb-0">
                Create a Support Ticket 
                  </h4>
      
              <h5 class="text-muted mb-0 mx-auto para-desc">
                Support over our ticketing system is available 24/7. 
              </h5>
              
              <div class="watch-video mt-4">

<div class="alert alert-primary alert-pills shadow"
                      role="alert"
                    >
                      <span class="content">
                        Only members can submit ticket</span
                      >
                    </div>

              </div>


              <p class="mb-4">
                We've answered over 
                <span class="my-color-1">
                  <countTo
                    :startVal="1"
                    :endVal="14719"
                    :duration="4000"
                  ></countTo
                  >+
                </span>
                tickets already<span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where KD Network is the digital agency management area of ' KD ' to offer digital marketing service and handle full operations. 
                Sign up and check our best website design price in bangladesh" class="my-color-1 font-weight-bold">*</span> 
              </p>


           

            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->


     <!-- Start Contact -->





      <div class="container">
        <div class="card rounded bg-light shadow border-0">
          <div class="card-body">
            <div class="row align-items-center">
             



              <iframe width="100%" height="800" src="https://members.karukaj.net/forms/ticket" frameborder="0" allowfullscreen></iframe>

            </div>
            <!--end row -->
          </div>
        </div>
      </div>
      <!--end container-->
















      <div class="container  mt-40 ">
        <div class="row justify-content-center" id="counter">
          <div class="col-12 text-center">
            <div class="section-title">
              
              <p class="text-muted para-desc mx-auto mb-0">



                <strong>N.B.</strong> There will be no response to any bad practice of marketing; like a internet bot, or anyone else who send irrelevant text or link or file using this form.

                       



              </p>




            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->





      
    </section>
    <!--end section-->















    <!-- Start -->
    <div class="container-fluid px-0">
      <div class="py-5 bg-footer">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-7">
              <div class="section-title">
                <div class="media">
                  <i
                    class="uil uil-award display-4 text-white title-dark"
                  ></i>
                  <div class="media-body ml-md-4 ml-3">
                    <h4 class="font-weight-bold text-light title-dark mb-1">
                      Start a Meeting
                    </h4>
                    <p class="text-white-50 mb-0">
                     We are just a click away and<br>
can’t wait to hear from you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-5 mt-4 mt-sm-0">
              <div class="text-md-right ml-5 ml-sm-0">
                <a
                  href="tel:+8801714641541"
                  class="btn btn-primary mr-2 mr-lg-2 mr-md-0 my-2"
                  data-toggle="modal"
                  data-target="#productview"
                  ><i class="uil uil-file-alt"></i> Give us a ring </a
                >
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->







        
      </div>
      <!--end div-->
    </div>
    <!--end container-->
    <!-- End -->


    




    <footer class="footer footer-bar">

            <div class="footer-wave">

<!--Content before waves-->

<!--Waves Container-->


      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              
              <p class="mycopyright">
     All the entirety of this domain is protected by copyright © 2010 - {{ new Date().getFullYear() }} <br> <small> Stream url: 
      <a href="https://karukaj.net/"
                  target="_blank"
                  class="text-reset"
                  > karukaj.net</a > | Stay with Good Works!</small>
              </p>

              
            </div>
          </div>
          <!--end col-->

          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul class="list-unstyled text-sm-right mb-0">
              <li class="list-inline-item">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/american-ex.png"
                    class="avatar avatar-ex-sm"
                    title="American Express"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/discover.png"
                    class="avatar avatar-ex-sm"
                    title="Discover"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/master-card.png"
                    class="avatar avatar-ex-sm"
                    title="Master Card"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/paypal.png"
                    class="avatar avatar-ex-sm"
                    title="Paypal"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/visa.png"
                    class="avatar avatar-ex-sm"
                    title="Visa"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->




<svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
<defs>


<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>


<g class="parallax">
<use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
<use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
<use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
<use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
</g>
</svg>
</div>




    </footer>
    <!--end footer-->










    <!-- Footer End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-light back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

